import {
  DataQuery,
  OrderByExpression,
  WhereCondition,
  WhereExpression,
  WhereOperator,
  WherePredicateExpression,
} from '@npm-libs/ng-templater';
import { Adaptor, AdaptorOptions, DataManager, DataUtil, Deferred, Predicate, Query, RequestOptions } from '@syncfusion/ej2-data';

const queryWherePredicateOperatorMap: { [x: string]: string | undefined } = {
  ...DataUtil.odUniOperator,
  ...DataUtil.odv4UniOperator,
  ...DataUtil.odBiOperator,
  wildcard: 'wildcard',
  like: 'like',
  isnull: 'isnull',
  isnotnull: 'isnotnull',
  isempty: 'isempty',
  isnotempty: 'isnotempty',
};

export type QueryData = unknown[] | { data?: unknown; count?: unknown } | undefined;

export class TemplaterSFDataAdaptor extends Adaptor implements AdaptorOptions {
  constructor(private doQuery: (data: DataQuery) => Promise<QueryData> | QueryData) {
    super();
  }

  private transformWherePredicate(predicate: Predicate): WhereExpression | undefined {
    const operator = queryWherePredicateOperatorMap[predicate.operator];

    if (operator) {
      const opExp: WherePredicateExpression = {
        field: predicate.field,
        operator: operator.trim() as WhereOperator,
        ignoreCase: predicate.ignoreCase,
        value: predicate.value,
      };

      return opExp;
    }

    if (predicate.condition && predicate.predicates) {
      let pCondition = predicate.condition;

      if (pCondition === 'andnot') pCondition = 'and not';
      if (pCondition === 'ornot') pCondition = 'or not';

      const condition = pCondition as WhereCondition;

      const predicates = predicate.predicates.map((p) => this.transformWherePredicate(p)).filter((p) => p) as WhereExpression[];

      return {
        condition,
        predicates,
      };
    }

    return undefined;
  }

  processQuery(dataManager: DataManager, query: Query): DataQuery {
    if (query?.dataManager?.adaptor instanceof TemplaterSFDataAdaptor && query?.dataManager?.adaptor !== this) {
      return query.dataManager.adaptor.processQuery(dataManager, query);
    }

    const onPage = query.queries.find((q) => q.fn === 'onPage')?.e;
    const onTake = query.queries.find((q) => q.fn === 'onTake')?.e;

    const where = query.queries
      .filter((q) => q.fn === 'onWhere')
      .map(({ e }) => (e ? this.transformWherePredicate(e as Predicate) : undefined))
      .filter((e) => e) as WhereExpression[];

    const orderBy = query.queries
      .filter((q) => q.fn === 'onSortBy')
      .map(({ e }) => (e ? { field: e.fieldName, direction: e.direction } : undefined))
      .filter((e) => e)
      .reverse() as OrderByExpression[];

    const page = {
      limit: onPage?.pageSize ?? onTake?.nos,
      offset: (onPage?.pageSize ?? 0) * ((onPage?.pageIndex ?? 0) - 1),
    };

    return {
      where: where.length ? where : undefined,
      orderBy: orderBy.length ? orderBy : undefined,
      distinct: query.distincts.length ? query.distincts : undefined,
      ...page,
    };
  }

  async makeRequest(data: DataQuery, deferred: Deferred, args?: RequestOptions, query?: Query): Promise<void> {
    if (query?.dataManager?.adaptor instanceof TemplaterSFDataAdaptor && query?.dataManager?.adaptor !== this) {
      return query.dataManager.adaptor.makeRequest(data, deferred, args, query);
    }

    const d = (await this.doQuery(data)) ?? {};

    let result: unknown;
    let count: unknown;

    if (Array.isArray(d)) {
      result = d;
      count = d.length;
    } else if ('data' in d && Array.isArray(d.data)) {
      result = d.data;
      count = 'count' in d ? d.count : d.data.length;
    } else {
      result = [];
      count = 0;
    }

    args ??= {};

    Object.assign(args, {
      result,
      count,
    });

    deferred.resolve(args);
  }
}
